import axios from "axios";

const config = {
    headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
    }
}

export async function getNotes() {
    const url = window.location.href.split('/')[3]
    return await axios.get('https://mystery-panda.com/api/collections/' + url)
}

export async function addNote({title, content, renderIndex}, coll) {
    const json = JSON.stringify({title: title, content: content, renderIndex: renderIndex});
    return axios.post('https://mystery-panda.com/api/collections/' + coll + '/notes', json, config)
}

export async function deleteNote(coll, id) {
    return axios.delete('https://mystery-panda.com/api/collections/' + coll + '/notes/' + id)
}

export async function editNote(note) {
    const url = window.location.href.split('/')[3]
    const json = JSON.stringify(note)
    return axios.patch('https://mystery-panda.com/api/collections/' + url + '/notes/' + note.id, json, config)
}

export async function updateIndexes(notes) {
    const url = window.location.href.split('/')[3]
    const json = JSON.stringify(notes)
    return axios.patch('https://mystery-panda.com/api/collections/' + url + '/utils/indexes', json, config)
}