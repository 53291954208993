import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Home from "./components/Home";

function Router() {
    let currentPage = <App />

    const url = window.location.href.split('/')[3]
    if (url === "") currentPage = <Home />

    ReactDOM.render(currentPage, document.getElementById('root'));
}

export default Router;