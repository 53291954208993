import styled from "styled-components";
import {CgNotes} from 'react-icons/cg'
import React from 'react'

const HeaderWrapper = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 2px 0 rgb(114, 114, 114);
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
  color: #5f6368;

  h3 {
    font-size: 1.5rem;
    margin-left: 10px;
  }
`

export default function Header() {
    return (
        <HeaderWrapper>
            <CgNotes size={36} color='#5f6368'/>
            <h3>Cool Notes</h3>
        </HeaderWrapper>
    )
}