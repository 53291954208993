export const gridOptions = {
    dragSortHeuristics: {
        sortInterval: 40,
    },
    layoutDuration: 400,
    dragRelease: {
        duration: 400,
        easing: "ease-out"
    },
    dragEnabled: true,
    dragStartPredicate: {
        distance: 10,
        delay: 0,
    },
    dragPlaceholder: {
        enabled: true,
        createElement: function(item) {
            // The element will have the Css class ".muuri-item-placeholder".
            return item.getElement().cloneNode(true);
        }
    }
}