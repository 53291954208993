import React, {useEffect, useRef, useState} from "react";

import {IoIosAdd} from 'react-icons/io'
import styled from "styled-components";
import {addNote} from "../my-api";

const FormWrapper = styled.form`
  position: relative;
  max-width: 600px;
  margin: 32px auto 50px auto;
  padding: 7px;
  background-color: #FCEC98;
  //box-shadow: 4px 4px 0 #EDCF82;
  border-radius: var(--note-border-radius);

  input {
    width: 100%;
    border: none;
    padding: 4px 10px;
    margin-bottom: 20px;
    outline: none;
    font-size: 1rem;
    resize: none;
    background-color: inherit;
    color: inherit;
  }

  textarea {
    width: 100%;
    border: none;
    padding: 4px 10px;
    outline: none;
    font-size: 1rem;
    resize: none;
    background-color: inherit;
    color: inherit;
  }

  button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 18px;
    bottom: -18px;
    background: rgb(248, 144, 26);
    color: #ffffff;
    border: none;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    box-shadow: 0 1px 3px rgb(165, 165, 165);
    cursor: pointer;
    outline: none;
  }
`

export default function CreateArea({onInsert, notesCount}) {
    const [isExpanded, setExpanded] = useState(false)
    const [note, setNote] = useState({
        title: "",
        content: ""
    })

    /**
     * Hook that alerts clicks outside of the passed ref
     */
    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    // Outside
                    setExpanded(false)
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function handleChange(e) {
        const {name, value} = e.target
        setNote(preValue => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }

    async function handleSubmit(event) {
        event.preventDefault()
        const newNote = {title: note.title, content: note.content, renderIndex: notesCount}
        const slug = window.location.href.split('/')[3]
        addNote(newNote, slug).then(r => {
            if (r.status === 200) {
                onInsert({id: r.data, Title: note.title, Content: note.content, RenderIndex: notesCount, Color: '#FCEC98'})
                setNote({
                    title: "",
                    content: ""
                })
                setExpanded(false)
            }
        }).catch((e) => {console.log(e)})
    }

    function handleExpanded(event) {
        setExpanded(true)
    }

    return (
        <FormWrapper ref={wrapperRef}>
            {isExpanded && (
                <input value={note.title} type="text" placeholder="Title" name="title" onChange={handleChange}/>
            )}
            <p>
                <textarea
                    value={note.content}
                    onClick={handleExpanded}
                    name="content"
                    placeholder="Take a note..."
                    onChange={handleChange}
                    rows={isExpanded ? 3 : 1}>
                </textarea>
            </p>
            <button onClick={handleSubmit}><IoIosAdd size={35}/></button>
        </FormWrapper>
    )
}