import Note from "./components/Note";
import {useCallback, useEffect, useRef, useState} from "react";
import CreateArea from "./components/CreateArea";
import {MuuriComponent} from "muuri-react";
import EditArea from "./components/EditArea";
import React from "react";
import Header from "./components/Header";
import {editNote, getNotes, updateIndexes} from "./my-api";
import {gridOptions} from "./utils";
import styled from "styled-components";

const MuuriWrapper = styled.div`
  padding: 0 ${props => props.padding}px;

  @media (max-width: 520px) {
    padding: 0;
  }
`

function App() {
    const [notes, setNotes] = useState([])
    const [currentEdit, setCurrentEdit] = useState(null)
    const [gridPadding, setGridPadding] = useState(0)
    const muuriWrapperRef = useRef(null)

    function handleResize() {
        if (muuriWrapperRef.current != null) {
            const tmp = (muuriWrapperRef.current.offsetWidth - (Math.floor(muuriWrapperRef.current.offsetWidth / 244) * 244))
            setGridPadding(tmp)
        }
    }

    useEffect(() => {
        async function getCurrentNotes() {
            await getNotes().then(r => {
                if (r.data == null) return
                r.data.sort((a, b) => {return a.RenderIndex - b.RenderIndex})
                setNotes(r.data)
            }).catch(e => console.log(e))
        }
        getCurrentNotes().then()

        handleResize()
        //window.addEventListener('resize', handleResize)
    }, [])

    const onInsertNote = useCallback((note) => {
        setNotes(notes ? [...notes, note] : [note])
    }, [notes])

    const onDeleteNote = useCallback((id) => {
        setNotes(notes.filter((note) => note.id !== id))
    }, [notes])

    const onOpenEditMode = useCallback((id) => {
        const note = notes.find((n) => {return n.id === id})
        setCurrentEdit(note)
    }, [notes])

    const onCloseEditMode = useCallback((editedNote) => {
        setCurrentEdit(null)
        const noteInd = notes.findIndex((n) => {return n.id === editedNote.id})
        if (notes[noteInd].Title === editedNote.Title && notes[noteInd].Content === editedNote.Content) return
        notes[noteInd].Title = editedNote.Title
        notes[noteInd].Content = editedNote.Content
        editNote(notes[noteInd])
    }, [notes])

    const updateNote = useCallback((editedNote) => {
        const noteInd = notes.findIndex((n) => {return n.id === editedNote.id})
        notes[noteInd] = editedNote
        editNote(notes[noteInd])
        setNotes([...notes])
    }, [notes])

    const onDragEnd = (item) => {
        const newNotes = [];
        for (let i = 0; i < item.getGrid()._items.length; i++) {
            const index = item.getGrid()._items[i]._component.data.index
            //const id = item.getGrid()._items[i].getKey()
            if (notes[index].RenderIndex !== i) {
                notes[index].RenderIndex = i; // i can do this because i don't need to trigger setState and rerender component
                newNotes.push(notes[index])
                //newNotes[newNotes.length - 1].RenderIndex = i
            }
        }

        if (newNotes.length === 0) return
        updateIndexes(newNotes)
    }

    const renderNote = (note, i) => <Note note={note} key={note.id} onDelete={onDeleteNote} onEdit={onOpenEditMode} index={i} isEditing={currentEdit && currentEdit.id === note.id} onUpdate={updateNote} />
    const notesArr = notes.map(renderNote)

    return (
        <div className="App">
            <Header />
            <CreateArea onInsert={onInsertNote} notesCount={notes.length} />
            <MuuriWrapper ref={muuriWrapperRef} padding={gridPadding}>
                <MuuriComponent {...gridOptions} onDragEnd={onDragEnd} propsToData={({index}) => {return {index}}}>
                    {notesArr}
                </MuuriComponent>
            </MuuriWrapper>
            {currentEdit && <EditArea note={currentEdit} onClose={onCloseEditMode} />}
        </div>
    )
}

export default App;
