import styled, {keyframes} from "styled-components";
import {AiOutlineDelete} from 'react-icons/ai'
import {MdOutlineColorLens} from 'react-icons/md'
import {useDrag, useRefresh} from "muuri-react";
import {useState} from "react";
import React from "react";
import {adjustHue, darken, desaturate} from "polished";
import {deleteNote} from "../my-api";

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const NoteOuter = styled.div`
  width: 240px;
  margin: 4px;
  will-change: transform;
  
  :hover {
    z-index: 5;
  }

  @media (max-width: 520px) {
    width: 100%;
    margin: 4px 0;
  }
`

const NoteInner = styled.div`
  padding: 8px 16px 32px 16px;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: left;
  display: block;
  background-color: ${props => props.color};
    //box-shadow: 4px 4px 0 ${props => desaturate(0.2, adjustHue(-8, darken(0.45, props.color)))};
  border-radius: var(--note-border-radius);

  visibility: ${props => props.visibility};
  animation: ${props => props.isDeleting ? fadeOut : ""} 0.4s;

  :hover {
    box-shadow: 0 0 4px #494949;
  }
`

const Title = styled.h1`
  font-size: 1.3rem;
  margin-bottom: 8px;
`

const Content = styled.div`
  word-wrap: break-word;
  white-space: pre-wrap;
`

const Toolbar = styled.div`
  margin-top: 16px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease-out;
  
  ${NoteInner}:hover & {
    visibility: visible;
    opacity: 1;
  }

  z-index: 5;
`

const ToolbarButton = styled.div`
  position: relative;
  float: right;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  margin-left: 8px;
`

const PaletteButton = styled(ToolbarButton)`
  z-index: 5;
`

const ColorPalette = styled.div`
  visibility: hidden;
  position: absolute;
  width: 128px;
  background-color: black;
  transition: opacity 0.5s ease-out;
  z-index: 6;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.25);
  transform: translate(-60%, -150%);
  padding: 4px 4px;
  
  ${PaletteButton}:hover & {
    visibility: visible;
  }
  
  button {
    z-index: 7;
  }
`

const ColorButtonWrapper = styled.button`
  background-color: ${props => props.color};
  border: none;
  text-decoration: none;
`

export default function Note({note, onDelete, onEdit, isEditing, onUpdate}) {
    const isDragging = useDrag()
    const [isDeleting, setIsDeleting] = useState(false)
    const [isCurrent, setIsCurrent] = useState(false)

    useRefresh([note.Title, note.Content])

    async function delete_note() {
        const coll = window.location.href.split('/')[3]
        setIsDeleting(true)
        await deleteNote(coll, note.id).then(r => {
            if (r.status === 200) {
                onDelete(note.id)
            }
        }).catch(e => console.log(e))
    }

    const onMouseUp = () => {
        if (!isDragging && !isDeleting && isCurrent) {
            onEdit(note.id)
        }
        setIsCurrent(false)
    }

    const onMouseDown = () => {
        setIsCurrent(true)
    }

    const ColorButton = ({color, children}) => {
        function changeColor(newColor) {
            //setColor(newColor)
            //note.Color = newColor
            var editedNote = note;
            editedNote.Color = newColor
            onUpdate(editedNote)
        }

        return(
            <ColorButtonWrapper onClick={() => {changeColor(color)}} color={color}>{children}</ColorButtonWrapper>
        )
    }

    const color = note.Color === '' ? '#FCEC98' : note.Color

    // <NoteWrapper onMouseUp={onMouseUp} visibility={isEditing ? 'hidden' : 'visible'}>
    return (
        <NoteOuter className='item'>
            <NoteInner className='item-content' isDeleting={isDeleting} visibility={isEditing ? 'hidden' : 'visible'} color={color}>
                <Title onMouseDown={onMouseDown} onMouseUp={onMouseUp}>{note.Title}</Title>
                <Content onMouseDown={onMouseDown} onMouseUp={onMouseUp}>{note.Content}</Content>
                <Toolbar>
                    <ToolbarButton onMouseDown={delete_note}>
                        <AiOutlineDelete size={20}/>
                    </ToolbarButton>
                    <PaletteButton>
                        <MdOutlineColorLens size={19}/>
                        <ColorPalette>
                            <ColorButton color={'#FCB098'}>Red</ColorButton>
                            <ColorButton color={'#B8FC98'}>Green</ColorButton>
                            <ColorButton color={'#FCEC98'}>Yellow</ColorButton>
                            <ColorButton color={'#98F0FC'}>Cian</ColorButton>
                            <ColorButton color={'#98B4FC'}>Blue</ColorButton>
                        </ColorPalette>
                    </PaletteButton>
                </Toolbar>
            </NoteInner>
        </NoteOuter>
    )
}