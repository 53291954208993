import styled from "styled-components";
import {useEffect, useState} from "react";
import React from "react";

const EditAreaWrapper = styled.form`
  padding: 16px;
  position: fixed;
  z-index: 7;
  width: 400px;
  background-color: ${props => props.color};
  //box-shadow: 4px 4px 0 #EDCF82;
  box-sizing: border-box;
  left: 50%;
  top: 200px;
  transform: translateX(-50%);
  border-radius: var(--note-border-radius);
  
  h1 {
    font-size: 1.5rem;
    margin-bottom: 8px;
  }

  input {
    width: 100%;
    border: none;
    padding: 4px 10px;
    margin-bottom: 20px;
    outline: none;
    font-size: 1rem;
    resize: none;
    background-color: inherit;
    color: inherit;
  }

  textarea {
    width: 100%;
    border: none;
    padding: 4px 10px;
    outline: none;
    font-size: 1rem;
    resize: none;
    background-color: inherit;
    color: inherit;
    height: 360px;
  }
`

const BackgroundBlur = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 6;
  background-color: #000000;
  opacity: 0.75;
`

export default function EditArea({note, onClose}) {
    const [tmpNote, setTmpNote] = useState({Title: "", Content: ""})

    useEffect(() => {
        setTmpNote({...note})
    }, [note])

    function handleChange(e) {
        const {name, value} = e.target
        setTmpNote(preValue => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }

    function closeEdit() {
        onClose(tmpNote)
    }

    const color = note.Color === '' ? '#FCEC98' : note.Color;

    return(
        <div>
            <BackgroundBlur onClick={closeEdit}/>
            <EditAreaWrapper color={color}>
                <input value={tmpNote.Title} type="text" placeholder="Title" name="Title" onChange={handleChange} />
                <p>
                    <textarea
                        value={tmpNote.Content}
                        name="Content"
                        placeholder="Take a note..."
                        rows={3}
                        onChange={handleChange}>
                    </textarea>
                </p>
            </EditAreaWrapper>
        </div>
    )
}